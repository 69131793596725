import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { logError } from '../../../../Utilities';
import { OnboardingFlowHandlerActions } from '../../../../Redux/Services/OnboardingFlowHandler/actions';
import { useTranslation } from 'react-i18next';
import LoaderWithText from '../../../ApplicationKYC/QuestionnaireStepsRender/components/loaderWithText/loaderWithText';
import { CompanyDisplayContainer } from './CompanyDisplayContainer/CompanyDisplayContainer';
import { Modal } from '@single-platform/components';

export const CompanyModal = ({ onCancel, isOpen }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isSingleRegisteredCompany, setIsSingleRegisteredCompany] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  const handleCompanySelect = async (company) => {
    setIsLoading(true);
    try {
      await dispatch(OnboardingFlowHandlerActions.handleOnboardingFlow(company));
      setIsLoading(false);
    } catch (e) {
      logError(e);
      onCancel();
    }
  };

  useEffect(() => setIsModalOpen(isOpen), [isOpen]);

  const handleClose = () => {
    setIsModalOpen(false);
    onCancel();
  };

  if (isSingleRegisteredCompany) {
    return <LoaderWithText text={t('loader_preparing_questionnaire_text')} small />;
  }

  return (
    <>
      {isLoading ? (
        <Modal
          onCancel={onCancel}
          maskClosable={false}
          isOpen={isModalOpen}
          hideSecondaryAction={true}
        >
          <LoaderWithText text={t('loader_preparing_questionnaire_text')} small />
        </Modal>
      ) : (
        <CompanyDisplayContainer
          handleCompanySelect={handleCompanySelect}
          clickBackHandler={handleClose}
        />
      )}
    </>
  );
};

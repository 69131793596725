import React, { memo, useEffect, useState } from 'react';
import { QuestionnaireActions } from '@redux';
import { useDispatch, useSelector } from 'react-redux';
import RepeatableDrawer from '../../components/repeatableDrawer/repeatableDrawer';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { formKeyInEdit } from '../../../../../Redux/Selectors/questionnaireFlowHandle';

import person from '../../../../../Assets/images/logos/person.svg';
import company from '../../../../../Assets/images/logos/company.svg';
import { Button } from '@single-platform/button';
import { Arrow } from '../../../../../Assets/images/logos';
import { FormListStyles } from './renderRepeatableGroup.style';
import { QuestionTitle } from '../renderGroup/renderGroup.style';

export const RenderRepeatableGroup = memo(
  ({
    group,
    validationHelpers,
    currentPage,
    onQuestionChange,
    errors,
    isInModal,
    onRepeatableGroupChange
  }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isUncompletedModalOpen, setIsUncompletedModalOpen] = useState(false);
    const [entry, setEntry] = useState(null);
    const [list, setList] = useState([]);
    const [shownList, setShownList] = useState([]);
    const [sortedGroups, setSortedGroups] = useState([]);
    const [currentQuestionnaireVersion, setCurrentQuestionnaireVersion] = useState(null);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const questionnaireState = useSelector((state) => state.QuestionnaireReducer);
    const selectFormKeyInEdit = useSelector(formKeyInEdit);

    const isTheOnlyEntry = () => group?.entries?.length > group?.minEntries;

    const modalToggleHandler = (e) => {
      e?.preventDefault();
      setIsModalOpen(() => !isModalOpen);
    };

    const uncompletedModalToggleHandler = () => {
      setIsUncompletedModalOpen(() => !isUncompletedModalOpen);
    };

    const removeRepeatableHandler = async (e, entryId) => {
      e.preventDefault();
      await dispatch(
        QuestionnaireActions.removeQuestionnaireEntry(
          questionnaireState.questionnaireId,
          group.id,
          entryId
        )
      );
      setList(list.filter((item) => item.title !== shownList.title));
      await dispatch(QuestionnaireActions.setFormKeyInEdit(selectFormKeyInEdit + 1));
      uncompletedModalToggleHandler();
    };

    const getLogo = (summary) => {
      switch (summary.iconKey) {
        case 'icon_key.company':
          return company;
        default:
          return person;
      }
    };

    const createOptions = (choice) =>
      !choice.label ? { label: choice.title, value: choice.value } : choice;

    const getTranslatedAnswer = (question) => {
      const questionTypes = ['Multiselect', 'CheckboxGroup', 'RadioButtonGroup', 'Dropdown'];
      if (!questionTypes.includes(question.type)) return question.value;
      const choices = questionnaireState.choices?.[question.choiceKey]?.map(createOptions);
      if (choices && question.value) {
        return t(
          question.value
            .split('|')
            .map((questionValue) => choices.find((choice) => choice.value === questionValue).label)
            .join(', ')
        );
      }
    };

    const getAllQuestions = (question) => {
      if (question.conditionalGroups.length === 0) return [question];
      let allQuestions = [question];
      question.conditionalGroups.forEach((condGroup) => {
        condGroup.groups.forEach((group) => {
          group?.questions?.forEach((q) => {
            allQuestions.push(...getAllQuestions(q));
          });
        });
      });

      return allQuestions;
    };

    const flatQuestions = (questions) =>
      questions.reduce((acc, q) => {
        acc.push(...getAllQuestions(q));
        return acc;
      }, []);

    const replaceQuestionValues = (questionKeyString, dictionary) => {
      const regex = /\{([^}]+)\}/g;
      const matches = [...questionKeyString.matchAll(regex)].map((match) => match[1]);
      let replacedString = questionKeyString;
      if (matches.some((m) => !dictionary[m])) return '';
      matches.forEach((match) => {
        replacedString = replacedString.replace(`{${match}}`, dictionary[match]);
      });
      return replacedString;
    };

    const handleRepeatableCardClick = (index) => {
      setEntry(group.groupType === 'RepeatableGroup' ? sortedGroups[index] : group);
      setIsUncompletedModalOpen(true);
      setShownList(list[index]);
      dispatch(QuestionnaireActions.setFormKeyInEdit(selectFormKeyInEdit + 1));
    };

    const getList = (group) => {
      const dictionary = flatQuestions(group.questions).reduce((acc, question) => {
        acc[question.questionKey] = getTranslatedAnswer(question);
        if (question.type === 'Checkbox') {
          acc[question.questionKey] = t(question.title);
        }
        return acc;
      }, {});

      return {
        title: replaceQuestionValues(group.summary.header, dictionary),
        subtitle: group?.completed
          ? replaceQuestionValues(group.summary.text, dictionary)
          : !replaceQuestionValues(group.summary.header, dictionary) &&
            t('repeatable_group.uncompleted'),
        completed: !group?.completed,
        icon: getLogo(group.summary)
      };
    };

    useEffect(() => {
      onQuestionChange({});
      onRepeatableGroupChange(group, 'add');

      if (group.groupType === 'RepeatableGroup' && group.entries.length > 0) {
        const sortedGroups = group.entries.sort((a, b) => {
          return (
            b.questions[0].questionKey.split('_').at(-1) -
            a.questions[0].questionKey.split('_').at(-1)
          );
        });
        setSortedGroups(sortedGroups);
      }
      return () => {
        onRepeatableGroupChange(group, 'delete');
      };
    }, [questionnaireState.questionnaire, group, selectFormKeyInEdit]);

    useEffect(() => {
      if (group.groupType === 'RepeatableGroup')
        setList(sortedGroups.map((group) => getList(group)));
      dispatch(QuestionnaireActions.setFormKeyInEdit(selectFormKeyInEdit + 1));
    }, [sortedGroups]);

    useEffect(() => {
      if (group.groupType === 'SimpleGroup') setList([getList(group)]);
      dispatch(QuestionnaireActions.setFormKeyInEdit(selectFormKeyInEdit + 1));
    }, [group]);

    useEffect(() => setCurrentQuestionnaireVersion(questionnaireState.version), [
      questionnaireState.version
    ]);

    const handleExtraActions = (e, index) => {
      e.preventDefault();
      handleRepeatableCardClick(index);
    };

    useEffect(() => {
      if (isModalOpen || isUncompletedModalOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    }, [isModalOpen, isUncompletedModalOpen]);

    return (
      <>
        {currentQuestionnaireVersion < 7 && (
          <QuestionTitle>{t(`${group.groupKey}_title`)}</QuestionTitle>
        )}

        <FormListStyles
          data-hj-suppress
          addButtonLabel={group.baseGroup && t('repeatable_group.add_another_button')}
          onAddCardClick={group.baseGroup && modalToggleHandler}
          onItemClick={(index) => handleRepeatableCardClick(index)}
          renderExtraActions={(item, index) =>
            !item.completed ? (
              <Arrow onClick={(index) => handleRepeatableCardClick(index)} />
            ) : (
              <Button size="S" onClick={(e) => handleExtraActions(e, index)}>
                {t('repeatable_group.button_review')}
              </Button>
            )
          }
          list={[...list]}
        />

        {isModalOpen && (
          <RepeatableDrawer
            key={group.baseGroup ? group.baseGroup.id : group.id}
            group={group.baseGroup ? group.baseGroup : group}
            groupKey={group.groupKey}
            groupId={group.id}
            errors={errors}
            flow={'add'}
            currentPage={currentPage}
            isModalOpen={isModalOpen}
            modalToggleHandler={modalToggleHandler}
            shownList={[]}
            isInModal={isInModal}
          ></RepeatableDrawer>
        )}
        {isUncompletedModalOpen && entry && (
          <RepeatableDrawer
            key={entry.id}
            groupKey={entry.groupType}
            group={entry}
            groupId={group.id}
            flow={'edit'}
            errors={errors}
            currentPage={currentPage}
            allGroups={group.entries}
            validationHelpers={validationHelpers}
            isModalOpen={isUncompletedModalOpen}
            modalToggleHandler={uncompletedModalToggleHandler}
            onRemove={removeRepeatableHandler}
            removeButtonText={isTheOnlyEntry() && t('repeatable_group.remove_button')}
            setIsModalOpen={setIsModalOpen}
            shownList={shownList}
            isInModal={isInModal}
          ></RepeatableDrawer>
        )}
      </>
    );
  }
);

import React, { useEffect, useState } from 'react';
import { PageHeader } from '../ApplicationKYC/QuestionnaireStepsRender/screens/renderPage/renderPage.style';
import { Container, PageSubtitle } from './UserIdentification.styles';

import { Stepper } from './Components/Stepper/Stepper';
import { Trans, useTranslation } from 'react-i18next';
import { Toast } from '@single-platform/components';
import { logError } from '../../Utilities';
import { LoaderWithText } from '../ApplicationKYC/QuestionnaireStepsRender/components';

export const UserIdentification = ({
  actionInfo,
  title,
  steps,
  statusCallback,
  companyName,
  verificationCallback
}) => {
  const [error, setError] = useState(false);
  const [isFirst, setIsFirst] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  const getInfo = async () => {
    if (actionInfo.error) {
      setError(true);
      return;
    }

    if (actionInfo.appId || (actionInfo.groupId && actionInfo.externalPersonId)) {
      try {
        setIsLoading(true);
        await statusCallback();
        setIsLoading(false);
      } catch (e) {
        setError(true);
        logError(e);
      } finally {
        setIsFirst(false);
      }
    }
  };

  useEffect(() => {
    isFirst && getInfo();
  }, [actionInfo]);

  return (
    <>
      {isLoading ? (
        <LoaderWithText text={t('loading_text')} />
      ) : (
        <Container>
          {error ? (
            <Toast
              isVisible
              type="error"
              header={t('application_id_error')}
              message={t('application_id_error_message')}
            />
          ) : (
            <>
              <PageHeader>{t(title.header)}</PageHeader>
              <PageSubtitle>
                {actionInfo.userIdentificationAction === 'RepresentativeChange' ? (
                  t(title.subheader)
                ) : (
                  <Trans
                    t={t}
                    values={{
                      companyName: companyName
                    }}
                    i18nKey={title.subheader}
                  />
                )}
              </PageSubtitle>
              <Stepper steps={steps} verificationCallback={verificationCallback} />
            </>
          )}
        </Container>
      )}
    </>
  );
};

import { Link } from '@single-platform/components';
import styled from 'styled-components';
import {
  responsiveBreakPoints,
  bankColors
} from '../ApplicationKYC/QuestionnaireStepsRender/styled-theme-styles/styled-components.d';
import { colors } from '@single-platform/components/dist/design/designVariables';

export const OndatoErrorWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 1rem;

  @media (${responsiveBreakPoints.maxPhone}) {
    padding: 1rem;
  }
`;

export const OndatoErrorHeader = styled.h1`
  font-size: 1.5rem;
  font-weight: 500;
`;

export const OndatoErrorSubHeader = styled.h2`
  font-size: 1.375rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.base.black.shades[60]};
  font-size: 0.875rem;
  max-width: 33.75rem;
`;

export const LinkButtonWrapper = styled(Link)`
  color: ${bankColors.base.black.shades[60]};
  text-decoration: underline;
  font-size: 0.875rem;
  &:hover {
    color: ${({ isBank }) =>
      isBank ? bankColors.base.primary.shades[100] : colors.base.primary.shades[100]};
  }
`;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoginBubbleStyles, LoginBubbleTextStyles } from './LoginBubble.style';
import { ImageWrapper } from '../../../IndexContainer/indexContainer.styles';

export const LoginBubble = React.memo(() => {
  const { t } = useTranslation();

  return (
    <LoginBubbleStyles>
      <ImageWrapper>
        <img src="/images/slider/onboarding_login.png" alt="start onboarding icon" />
      </ImageWrapper>
      <LoginBubbleTextStyles>{t('loginBubble')}</LoginBubbleTextStyles>
    </LoginBubbleStyles>
  );
});

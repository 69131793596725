import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { KYCActions } from '@redux';
import { APP_STATES } from '@config';
import { logError } from '../../../../Utilities';
import { ButtonSection } from '../../QuestionnaireStepsRender/components';
import { Trans, useTranslation } from 'react-i18next';
import { ModalCompanyHeader } from '../../../Auth/Modals/CompanyModal/CompanyModal.style';
import { LegalBioModalUploadTextStyles } from './BioDocUploadModal.style';
import LoaderWithText from '../../QuestionnaireStepsRender/components/loaderWithText/loaderWithText';
import { Modal } from '@single-platform/components';

export const BioDocUploadModal = ({ onClose, onComplete, nextAction }) => {
  const dispatch = useDispatch();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const { t } = useTranslation();

  const addFileToList = (file) => {
    setUploadedFiles([...uploadedFiles, file]);
    return false;
  };

  const removeFileFromList = (file) => {
    const newFileList = [...uploadedFiles];
    const index = newFileList.indexOf(file);
    newFileList.splice(index, 1);
    setUploadedFiles(newFileList);
  };

  const getApplicationDocumentOfType = (type, applicationDocumentsList) => {
    const filteredManualSignedApplications = applicationDocumentsList
      .filter((application) => application.type === type)
      .sort((a, b) => {
        if (new Date(a.Created) < new Date(b.Created)) return 1;
        if (new Date(a.Created) > new Date(b.Created)) return -1;
        return 0;
      });
    const document =
      filteredManualSignedApplications?.[filteredManualSignedApplications.length - 1];
    return document;
  };

  const handleUpload = async () => {
    const formData = new FormData();
    uploadedFiles.forEach((item) => {
      formData.append('files', item);
    });
    try {
      if (nextAction === APP_STATES.AGREEMENT_SIGNING_STARTED) {
        //Check if agreement is working correctly
        setShowSpinner(true);
        try {
          await dispatch(KYCActions.startAgreementSigning('Biometrics'));
          await dispatch(KYCActions.uploadSignedAgreement(formData));
        } catch (e) {
          logError(e);
          setShowSpinner(false);
          setUploadedFiles([]);
          onClose();
        } finally {
          setShowSpinner(false);
          setUploadedFiles([]);
          onClose();
        }
      } else {
        setShowSpinner(true);
        const response = await dispatch(KYCActions.startApplicationSigning('Biometrics'));
        if (response) {
          const document = getApplicationDocumentOfType(
            'Application_manualSignature',
            response.payload.applicationDocumentsList
          );
          if (document)
            await dispatch(
              KYCActions.uploadSignedApplication(formData, document.applicationDocumentId)
            );
        }
      }
      setUploadedFiles([]);
      setShowSpinner(false);
      onComplete();
    } catch (e) {
      logError(e);
      setUploadedFiles([]);
    }
  };

  return (
    <Modal onCancel={onClose} maxWidth={'50rem'} isOpen={true} hideSecondaryAction={true}>
      {showSpinner ? (
        <LoaderWithText text={t('biometrics_upload_document_loader_text')} small={true} />
      ) : (
        <>
          <ModalCompanyHeader>{t('sign_with_Biometrics')}</ModalCompanyHeader>
          <Upload.Dragger
            fileList={uploadedFiles}
            beforeUpload={addFileToList}
            onRemove={removeFileFromList}
            accept=".pdf, .jpg, .png, .bmp, .jpeg, .doc, .docx"
            multiple
            id="file"
            action=""
          >
            <p className="ant-upload-drag-icon">
              <PlusOutlined />
            </p>
            <Trans i18nKey={'supported_file_types'} />
          </Upload.Dragger>

          <LegalBioModalUploadTextStyles>
            {t('agree_on_correct_document')}
          </LegalBioModalUploadTextStyles>
          <ButtonSection
            firstButtonClick={onClose}
            secondButtonClick={handleUpload}
            secondButtonPrimary={true}
            secondButtonDisabled={uploadedFiles.length === 0}
            firstButtonText={t('button_cancel')}
            secondButtonText={t('upload')}
            alignEnd={true}
          />
        </>
      )}
    </Modal>
  );
};

import styled from 'styled-components';

export const UnauthorizedCompanyModalButtonContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
`;

export const UnauthorizedCompanyModalSubTextStyle = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
`;

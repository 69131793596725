import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { PersonalDataFormContainer } from './personalDataForm.styles';
import input from '@single-platform/components/dist/components/input/input';
import { Field, Fieldset } from '@single-platform/components';
import { useFormValidation } from '../../../ApplicationKYC/QuestionnaireStepsRender/validation/useFormValidation';
import MobileInput from '../../../ApplicationKYC/QuestionnaireStepsRender/components/phoneInput/mobileInput';
import { FormButton } from '../countryForm/countryForm.styles';

const PersonalDataForm = ({ displayPhoneInput, onSubmit }) => {
  const initialValues = {
    customerId: '',
    phone: '',
    validatePhoneInput: displayPhoneInput,
    isValidPhoneNumber: true
  };
  const { t } = useTranslation();
  const validationSchema = Yup.object({
    customerId: Yup.string()
      .matches(/^[^\s]+$/, 'validation_customer_id_is_not_valid')
      .required(t('validation_required_field')),
    phone: Yup.string().when('validatePhoneInput', function (validatePhoneInput) {
      if (validatePhoneInput) {
        return Yup.string()
          .required(t('validation_required_field'))
          .test('isValidPhoneNumber', t('validation_incorrect_number'), (value) => {
            return initialValues.isValidPhoneNumber;
          });
      } else {
        return Yup.string().optional();
      }
    })
  });
  const disabled = !onSubmit;

  const { validationHelpers, getValues, setTouchedOnAll, handleSubmit } = useFormValidation({
    initialValues,
    validationSchema
  });

  const onBlurHandler = (isValid) => {
    initialValues.isValidPhoneNumber = isValid;
  };

  const onHandleSubmit = async () => {
    await handleSubmit(
      (data) => onSubmit?.(data),
      // on invalid case
      (data) => {
        setTouchedOnAll();
      }
    )();
  };

  return (
    <PersonalDataFormContainer>
      <Fieldset>
        <Field
          Component={input}
          name="customerId"
          validateOnChange
          value={getValues('customerId')}
          placeholder={t('question_person_code')}
          validationHelpers={validationHelpers}
          required
        />
        {displayPhoneInput ? (
          <MobileInput
            name="phone"
            value={getValues('phone')}
            validateOnBlur
            required
            onBlurHandler={onBlurHandler}
            placeholder={t('question_address_phone')}
            validationHelpers={validationHelpers}
          />
        ) : null}
      </Fieldset>
      <FormButton disabled={disabled} primary onClick={onHandleSubmit}>
        {t('flow_selection_button')}
      </FormButton>
    </PersonalDataFormContainer>
  );
};

export default PersonalDataForm;

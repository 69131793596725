import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { KYCActions } from '@redux';
import { downloadFile, logError } from '../../../Utilities';
import { Trans, useTranslation } from 'react-i18next';
import { selectKycAppState } from '../../../Redux';
import PdfDocView from '../../../Components/PdfDocument/PdfDocView';
import { TemplateDownload } from './templateDownload/templateDownload';
import LinkButtonSection from '../QuestionnaireStepsRender/components/linkButtonSection/linkButtonSection';
import { LinkButton } from '@single-platform/components';
import { FillOrgStructure } from '../Components/FillOrgStructure';

const VIEWABLE_FORMATS = ['.pdf'];

export const RequestedDocsListItem = ({
  documentRequestId,
  fileList,
  documentType,
  employeeComment,
  onFileUpload,
  onFileDelete
}) => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [comment, setComment] = useState('');
  const [fillOrgStructure, setFillOrgStructure] = useState(false);
  const [fillOrgStructureData, setFillOrgStructureData] = useState({});
  const [showPdf, setShowPdf] = useState(false);
  const [previewDocumentInfo, setPreviewDocumentInfo] = useState({});
  const { t } = useTranslation();
  const applicationState = useSelector(selectKycAppState);
  const companyInfo = useSelector((state) => state.KYCReducers.applicationCompany);
  const companyData = JSON.parse(sessionStorage.getItem('company'));

  const previewFile = (file) => {
    let extension;
    if (file?.name) extension = `.${file.name.split('.').pop()}`;

    if (!showPdf && VIEWABLE_FORMATS.includes(extension)) {
      setPreviewDocumentInfo({ documentId: documentRequestId, fileId: file.id });
      setTimeout(() => setShowPdf(true));
    } else if (file && extension) {
      const fileName = `${applicationState.applicationId}_${companyInfo.title}${extension}`;

      downloadFile({
        fileId: file.id,
        url: `${process.env.REACT_APP_API}Application/${applicationState.applicationId}/DocumentRequests/${documentRequestId}/File`,
        fileName
      });
      setPreviewDocumentInfo({});
      setShowPdf(false);
    } else {
      setPreviewDocumentInfo({});
      setShowPdf(false);
    }
  };

  const removeFile = async (file) => {
    await dispatch(
      KYCActions.deleteApplicationDocumentFile(
        applicationState?.applicationId,
        documentRequestId,
        file.id
      )
    );

    setFiles([]);
    onFileDelete();
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append('files', file);

    if (comment) {
      formData.append('comment', comment);
    }
    try {
      await dispatch(
        KYCActions.uploadApplicationDocument(
          formData,
          applicationState?.applicationId,
          documentRequestId
        )
      );
      setFiles([]);
      setComment('');

      onFileUpload();
    } catch (e) {
      logError(e);
    }
  };

  const orgStructureReadyToUpload = (data) => {
    const { orgStructure, orgStructureHtml } = data;
    if (orgStructure && orgStructureHtml) {
      setFillOrgStructureData(data);
      uploadFile(new File([data.orgStructurePdf], 'orgStructure.pdf'));
      setFillOrgStructure(true);
    }
  };

  useEffect(() => {
    if (fileList.length > 0) {
      setFiles(fileList);
    }
  }, [fileList]);

  return (
    <>
      {employeeComment && (
        <p>
          <b>{t('employee_comment')}</b>: {employeeComment}
        </p>
      )}
      {showPdf && previewDocumentInfo && (
        <Modal open={true} onCancel={previewFile} width="100%" footer={null}>
          <PdfDocView
            applicationId={applicationState.applicationId}
            documentInfo={previewDocumentInfo}
          />
        </Modal>
      )}
      <div className="w-100 mb-3 upload_card">
        {documentType === 'OrganizationalChart' && (
          <LinkButtonSection>
            <LinkButton onClick={() => setFillOrgStructure(false)}>
              {t('upload_org_structure_organizational_chart')}
            </LinkButton>
            <LinkButton onClick={() => setFillOrgStructure(true)}>
              {t('add_org_structure_organizational_chart')}
            </LinkButton>
          </LinkButtonSection>
        )}
        {documentType === 'PowerOfAttorney' && <TemplateDownload />}
      </div>

      {fillOrgStructure && (
        <FillOrgStructure
          beforeUpload={orgStructureReadyToUpload}
          orgStructure={fillOrgStructureData}
          applicationCompany={companyData}
        />
      )}

      {!fillOrgStructure && (
        <>
          <Upload.Dragger
            beforeUpload={(file) => uploadFile(file)}
            onRemove={(file) => removeFile(file)}
            className="mb-5 py-3"
            accept=".pdf, .jpg, .png, .bmp, .jpeg, .doc, .docx"
            multiple
            onPreview={(file) => previewFile(file)}
            fileList={files}
          >
            <p className="ant-upload-drag-icon">
              <PlusOutlined />
            </p>
            <Trans i18nKey={'supported_file_types'} />
          </Upload.Dragger>
        </>
      )}
    </>
  );
};

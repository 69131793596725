import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { OidcProvider } from 'redux-oidc';

import { userManager } from './Utilities';
import { store } from './Redux';
import { App } from './App';
// import './web.config';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import DefaultTheme from './Containers/ApplicationKYC/QuestionnaireStepsRender/styled-theme-styles/styled-components.d';
import { ThemeProvider } from 'styled-components';

import 'bootstrap/dist/css/bootstrap-reboot.min.css';
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import './PageParts/PartContent/index.less';
import './PageParts/PartTop/index.less';
import './globalStyles.less';

if (process.env.REACT_APP_CLIENT_ID === 'onboarding.prod' && process.env.REACT_APP_SENTRY_ID) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_ID,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  });
  Sentry.captureMessage('Something went wrong');
  // The above configuration captures both error and performance data. To reduce the volume of performance data captured, change tracesSampleRate to a value between 0 and 1.
}

render(
  <OidcProvider store={store} userManager={userManager}>
    <Provider store={store}>
      <ThemeProvider theme={DefaultTheme}>
        <App />
      </ThemeProvider>
    </Provider>
  </OidcProvider>,
  document.querySelector('#root')
);

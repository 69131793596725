import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Pagination } from 'antd';
import { saveAs } from 'file-saver';
import { DownloadOutlined } from '@ant-design/icons';
import { Button } from '@single-platform/button';
import { checkJsonProp } from '@utilities';
import { useTranslation } from 'react-i18next';
import { ButtonContainerStyles } from './PdfDocView.style';
import { LoaderWithText } from '../../Containers/ApplicationKYC/QuestionnaireStepsRender/components';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PdfDocView = ({
  applicationId,
  documentInfo,
  onClick,
  showButtons,
  authTypes,
  disabled,
  showBackButton
}) => {
  const [url, setUrl] = useState('');
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [width, setWidth] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const url = `${process.env.REACT_APP_API}Application/${applicationId}/DocumentRequests/${documentInfo.documentId}/File/${documentInfo.fileId}`;
    setUrl(getRequestPathForDocument(url));
    setDivSize();
    window.addEventListener('resize', setDivSize);

    return () => {
      window.removeEventListener('resize', setDivSize);
    };
  }, []);

  const getRequestPathForDocument = (url) => {
    const user = JSON.parse(sessionStorage.getItem('user'));
    const admin = JSON.parse(sessionStorage.getItem('admin'));
    let accessToken;

    if (user) {
      accessToken = checkJsonProp(user, 'token');
    } else if (admin) {
      accessToken = checkJsonProp(admin, 'access_token');
    }

    return {
      url: url,
      httpHeaders: { Authorization: `Bearer ${accessToken}` },
      withCredentials: true
    };
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  const handlePageChange = (page) => {
    setPageNumber(page);
  };
  const setDivSize = () => {
    const element = document.getElementById('pdfWrapper');
    setWidth(element.getBoundingClientRect().width);
  };

  const documentData = () => {
    return (
      <Document
        file={url}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={
          <div style={{ paddingTop: 60 }}>
            <LoaderWithText text={t('loading_text')} />
          </div>
        }
      >
        <Page pageNumber={pageNumber} width={width} />
      </Document>
    );
  };
  const pagination = () => (
    <Pagination
      hideOnSinglePage={true}
      pageSize={1}
      current={pageNumber}
      onChange={handlePageChange}
      defaultCurrent={1}
      total={numPages}
      className="text-center my-2"
      showLessItems={true}
      size={width <= 361 ? 'small' : 'default'}
    />
  );
  const handleClickOpenPDF = () => {
    const oReq = new XMLHttpRequest();
    oReq.open('GET', url.url, true);
    oReq.responseType = 'blob';
    // When the file request finishes
    // Is up to you, the configuration for error events etc.
    oReq.onload = () => {
      // Once the file is downloaded, open a new window with the PDF
      // Remember to allow the POP-UPS in your browser
      const file = new Blob([oReq.response], {
        type: 'application/pdf'
      });
      // Generate file download directly in the browser !
      saveAs(file, `${documentInfo.documentId}.pdf`);
    };
    oReq.send();
  };

  return (
    <div className="pdf-doc-view-wrapper">
      <div className={numPages ? 'pdf-doc-view-top' : 'hidden'}>
        <span onClick={handleClickOpenPDF} style={{ padding: 5 }}>
          <DownloadOutlined className="pdf-doc-view-img" />
          <span className="pdf-doc-view-link">{t('download_pdf')}</span>
        </span>
      </div>
      {showButtons && numPages && authTypes && (
        <ButtonContainerStyles center={!showBackButton}>
          {authTypes.map((authType) => (
            <Button
              key={authType}
              id={`pdf-button-${authType}`}
              className="mb-3 mr-1"
              disabled={disabled}
              onClick={() => onClick(authType)}
            >
              {t(`sign_with_${[authType]}`)}
            </Button>
          ))}
        </ButtonContainerStyles>
      )}
      {pagination()}
      <div id="pdfWrapper" style={{ width: '80%', margin: '0 auto' }}>
        {documentData()}
      </div>
      {pagination()}
    </div>
  );
};

export default PdfDocView;

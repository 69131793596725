import { AuthConstants } from './constants';
import { requestJSON } from '@redux';
import { checkJsonProp } from '@utilities';
import { Logout } from '../../../Utilities';

let user = JSON.parse(sessionStorage.getItem('company') || sessionStorage.getItem('token')),
  logged = user ? true : false;

export const getToken = (user) => {
  let access_token = checkJsonProp(user, 'token');
  return 'Bearer ' + access_token;
};

if (user) {
  if (requestJSON) {
    requestJSON.defaults.headers.common['Authorization'] = getToken(user);
  }
}

export const AuthReducers = function (state = { logged, initiatedSSOLogin: false }, action) {
  switch (action.type) {
    case AuthConstants.START_LOGIN_REQUEST:
      return {
        ...state,
        sendAuth: true,
        sendStartLogin: true,
        startLogin: null,
        logged: false
      };
    case AuthConstants.START_LOGIN_SUCCESS:
      sessionStorage.setItem('token', JSON.stringify(action.payload));
      return {
        ...state,
        sendAuth: false,
        sendStartLogin: false,
        startLogin: action.payload
      };
    case AuthConstants.START_LOGIN_ERROR:
      return {
        ...state,
        sendAuth: false,
        sendStartLogin: false,
        errors: action.error
      };
    case AuthConstants.GET_AUTHORISED_COMPANIES_REQUEST:
      return {
        ...state,
        sendAuth: true,
        isWaitingForConfirmation: true
      };
    case AuthConstants.GET_AUTHORISED_COMPANIES_SUCCESS:
      return {
        ...state,
        sendAuth: false,
        GetAuthorizedCompanies: action.payload,
        logged,
        isWaitingForConfirmation: true
      };
    case AuthConstants.GET_AUTHORISED_COMPANIES_ERROR:
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('company');
      Logout();
      return {
        ...state,
        sendAuth: false,
        errors: action.error,
        isWaitingForConfirmation: false
      };
    case AuthConstants.SELECT_IDENTIFICATION_REQUEST:
      return {
        ...state,
        sendAuth: true,
        isLoadingSelectId: true,
        selectIdentification: null
      };
    case AuthConstants.SELECT_IDENTIFICATION_SUCCESS:
      if (action.payload.status === 'Success') {
        sessionStorage.setItem('user', JSON.stringify(action.payload));
        requestJSON.defaults.headers.common['Authorization'] = getToken(action.payload);
        logged = true;
      }
      return {
        ...state,
        sendAuth: false,
        isLoadingSelectId: false,
        selectIdentification: action.payload,
        logged
      };
    case AuthConstants.SELECT_IDENTIFICATION_ERROR:
      return {
        ...state,
        sendAuth: false,
        isLoadingSelectId: false,
        errors: action.error
      };
    case AuthConstants.RESET_LOGIN:
      localStorage.removeItem('companyRepresentativeData');
      sessionStorage.clear();
      return {
        ...state,
        sendAuth: false,
        sendStartLogin: false,
        startLogin: undefined,
        GetAuthorizedCompanies: null,
        logged: false
      };
    case AuthConstants.GET_IDENTIFICATION_URL_REQUEST:
      return {
        ...state,
        sendAuth: true,
        identificationUrl: null
      };
    case AuthConstants.GET_IDENTIFICATION_URL_SUCCESS:
      return {
        ...state,
        sendAuth: false,
        identificationUrl: action.payload
      };
    case AuthConstants.GET_IDENTIFICATION_URL_ERROR:
      return {
        ...state,
        sendAuth: false
      };
    case AuthConstants.REFRESH_TOKEN_REQUEST:
      sessionStorage.removeItem('user');
      return {
        ...state,
        sendAuth: true,
        refreshToken: null,
        GetAuthorizedCompanies: null,
        isRefreshTokenLoading: true
      };
    case AuthConstants.REFRESH_TOKEN_SUCCESS: {
      let sessionToken = JSON.parse(sessionStorage.getItem('token')) || {};
      sessionToken.refreshToken = action.payload.refreshToken;
      sessionToken.accessToken = action.payload.token;
      sessionToken.expiresIn = action.payload.expiresIn;
      sessionStorage.setItem('token', JSON.stringify(sessionToken));
      return {
        ...state,
        sendAuth: false,
        isRefreshTokenLoading: false,
        refreshToken: action.payload
      };
    }
    case AuthConstants.REFRESH_TOKEN_ERROR:
      return {
        ...state,
        sendAuth: false,
        isRefreshTokenLoading: false,
        refreshToken: null,
        errors: action.error
      };

    case AuthConstants.BIOMETRICS_REQUEST:
    case AuthConstants.MOBILEID_REQUEST:
    case AuthConstants.SMARTID_REQUEST:
      return {
        ...state,
        isWaitingForConfirmation: true
      };

    case AuthConstants.BIOMETRICS_ERROR:
    case AuthConstants.SMARTID_ERROR:
    case AuthConstants.MOBILEID_ERROR:
      return {
        ...state,
        errors: action.error,
        isWaitingForConfirmation: false
      };

    case AuthConstants.SMARTID_SUCCESS:
      return {
        ...state,
        smartId: action.payload,
        isWaitingForConfirmation: true
      };

    case AuthConstants.BIOMETRICS_SUCCESS:
      return {
        ...state,
        biometrics: action.payload,
        isWaitingForConfirmation: true
      };

    case AuthConstants.MOBILEID_SUCCESS:
      return {
        ...state,
        mobileId: action.payload,
        isWaitingForConfirmation: true
      };

    case AuthConstants.AUTHORIZATION_STATUS_REQUEST:
      return {
        ...state
      };
    case AuthConstants.AUTHORIZATION_STATUS_SUCCESS:
      return {
        ...state
      };
    case AuthConstants.AUTHORIZATION_STATUS_ERROR:
      return {
        ...state
      };

    case AuthConstants.OTP_AUTORIZATION_REQUEST:
      return {
        ...state
      };
    case AuthConstants.OTP_AUTORIZATION_SUCCESS:
      return {
        ...state
      };
    case AuthConstants.OTP_AUTORIZATION_ERROR:
      return {
        ...state
      };
    case AuthConstants.EIDEASY_INIT:
      return {
        ...state,
        eideasy: action.payload
      };
    case AuthConstants.GET_COMPANIES_REQUEST:
      return {
        ...state
      };
    case AuthConstants.GET_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: action.payload
      };
    case AuthConstants.GET_COMPANIES_ERROR:
      return {
        ...state
      };
    case AuthConstants.DATA_EXCHANGE_REQUEST:
      return {
        ...state,
        dataExchangeCheck: true
      };
    case AuthConstants.DATA_EXCHANGE_SUCCESS:
      return {
        ...state,
        agreeToSellDataToOtherCompany: action.payload,
        dataExchangeCheck: false
      };
    case AuthConstants.DATA_EXCHANGE_ERROR:
      return {
        ...state,
        dataExchangeCheck: false
      };
    case AuthConstants.INITIATED_SSO_LOGIN:
      return {
        ...state,
        initiatedSSOLogin: true
      };
    case AuthConstants.RESET_SSO_LOGIN:
      return {
        ...state,
        initiatedSSOLogin: false
      };
    default:
      return state;
  }
};

import React from 'react';
import { useSelector } from 'react-redux';
import { LogoutOutlined } from '@ant-design/icons';
import { Logout } from '../../Utilities';
import { useTranslation } from 'react-i18next';
import { OnboardingFlowState } from '../../Redux/Selectors/questionnaireFlowHandle';
import useWindowDimensions from '../../Containers/ApplicationKYC/QuestionnaireStepsRender/Utils/window-size.service';
import { LogoutButtonStyles } from './logoutButton.style';

export const LogoutButton = () => {
  const { width } = useWindowDimensions();
  const onboardingFlowState = useSelector(OnboardingFlowState);
  const { t } = useTranslation();
  return onboardingFlowState.logged ? (
    <LogoutButtonStyles
      type="link"
      key="logout"
      className="text-click ml-1"
      onClick={() => Logout()}
    >
      <LogoutOutlined /> {width < 600 ? null : t('button_logout')}
    </LogoutButtonStyles>
  ) : (
    <></>
  );
};

import countries from '../../../Auth/components/countriesDropdown/loginCountriesList.json';
const isBank = process.env.REACT_APP_TRADEMARK.includes('Bank');
const countriesWithBioLogin = isBank ? countries.map((country) => country.value) : [];
const countriesWithSmartIdLogin = ['LT', 'EE', 'LV'];
const countriesWithMobileIdLogin = ['LT', 'EE'];
const countriesWithEideasyLogin = ['FI', 'LV', 'NL'];

export const availableLoginMethodsByCountry = (country) => {
  const availableLoginMethods = [];
  if (countriesWithSmartIdLogin.includes(country)) {
    availableLoginMethods.push('Smart-ID');
  }
  if (countriesWithMobileIdLogin.includes(country)) {
    availableLoginMethods.push('Mobile-ID');
  }
  if (countriesWithBioLogin.includes(country)) {
    availableLoginMethods.push('Biometrics');
  }
  if (countriesWithEideasyLogin.includes(country) && !isBank) {
    country === 'NL' && availableLoginMethods.push('IDIN');
    country === 'FI' && availableLoginMethods.push('FTN');
    country === 'LV' && availableLoginMethods.push('eParakts');
  }
  if (countriesWithEideasyLogin.includes(country) && isBank) {
    country === 'FI' && availableLoginMethods.push('FTN');
    // country === 'NL' && availableLoginMethods.push('IDIN');
  }
  return availableLoginMethods;
};

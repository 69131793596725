import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModalCompanyHeader } from '../../Containers/Auth/Modals/CompanyModal/CompanyModal.style';
import { Button } from '@single-platform/button';
import {
  UnauthorizedCompanyModalButtonContainer,
  UnauthorizedCompanyModalSubTextStyle
} from './UnauthorizedCompanyModal.style';
import CreateEmptyCompanyButton from '../../Containers/Auth/Modals/CompanyModal/CompanyDisplayContainer/CreateEmptyCompanyButton/CreateEmptyCompanyButton';
import { Modal } from '@single-platform/components';

export const UnauthorizedCompanyModal = ({ onCancel }) => {
  const { t } = useTranslation();
  return (
    <Modal onCancel={onCancel} maskClosable={false} isOpen={true} hideSecondaryAction={true}>
      <ModalCompanyHeader>{t('no_authorized_company_text')}</ModalCompanyHeader>
      {(process.env.REACT_APP_ENV === 'TEST' || process.env.REACT_APP_ENV === 'STAGE') && (
        <CreateEmptyCompanyButton />
      )}
      <UnauthorizedCompanyModalSubTextStyle>
        {t('no_authorized_compnay_sub_text')}
      </UnauthorizedCompanyModalSubTextStyle>
      <UnauthorizedCompanyModalButtonContainer>
        <Button onClick={onCancel}>{t('button_cancel')}</Button>
      </UnauthorizedCompanyModalButtonContainer>
    </Modal>
  );
};

import React from 'react';
import {
  CompanyCodeStyles,
  CompanyContainerStyles,
  CompanyNameCodeWrapper,
  CompanyNameStyles,
  RadioSelectWrapper
} from './CompanyContainer.style';
import { RadioButton } from '@single-platform/components';
import { useTranslation } from 'react-i18next';

export const CompanyContainer = ({ company, onChangeHandler, isSelected }) => {
  const { t } = useTranslation();
  return (
    <CompanyContainerStyles
      isSelected={isSelected}
      onClick={() => onChangeHandler(company.identificationId, company.companyName)}
    >
      <RadioSelectWrapper>
        <RadioButton
          style={{ width: '1.2rem' }}
          name={company.identificationId}
          key={company.identificationId}
          id={company.identificationId}
          required={true}
          checked={isSelected}
        />
      </RadioSelectWrapper>
      <CompanyNameCodeWrapper>
        <CompanyNameStyles>{company.companyName || t('register_new_company')}</CompanyNameStyles>
        <CompanyCodeStyles>
          {company.companyCode || t('register_new_company_sub_text')}
        </CompanyCodeStyles>
      </CompanyNameCodeWrapper>
    </CompanyContainerStyles>
  );
};

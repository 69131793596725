import './translations/translations';
import React, { useEffect, useState } from 'react';
import { RenderPage } from './screens/renderPage/renderPage';
import { QuestionnaireActions } from '@redux';
import { useDispatch, useSelector } from 'react-redux';
import { KYCActions, OnboardingFlowHandlerActions } from '../../../Redux';
import { QuestionnaireState } from '../../../Redux/Selectors/questionnaireFlowHandle';
import { history, routerPath } from '../../../Config';
import LoaderWithText from './components/loaderWithText/loaderWithText';
import { useTranslation } from 'react-i18next';
import { getUrlQueryParameter } from './hooks/setUrlQueryParameter';

export const QuestionnaireStepsRender = () => {
  const [pages, setPages] = useState([]);
  const [pageToShow, setPageToShow] = useState(+localStorage.getItem('lastActivePage') || 1);
  const [steps, setSteps] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [isFirst, setIsFirst] = useState(true);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const questionnaireState = useSelector(QuestionnaireState);

  const handleSteps = (pageToSet) => {
    setPageToShow(pageToSet);
    localStorage.setItem('lastActivePage', pageToSet);
  };

  const allPagesCompleted = pages.slice(0, -1)?.every((page) => page.completed);

  const handleStepForward = () => {
    if (pageToShow < pages.length) {
      handleSteps(() => +pageToShow + 1);
    } else {
      handleSteps(() => pages.length);
    }
  };

  const handleStepBack = () => {
    if (pageToShow > 1) {
      handleSteps(() => +pageToShow - 1);
    }
  };

  const handleNotCompletedStep = () => {
    handleSteps(pages?.filter((page) => !page.completed)[0]?.position || pages.length);
  };

  const stepperSteps = (steps) => {
    const checkStepStatus = (step) => {
      const activeIndex =
        step.isCompleted === true || step.completionIndex - 1 === step.previousActiveStep;
      return activeIndex;
    };

    const checkAllNotCompletedStepsAfterCompletedOne = (step) => {
      const notCompletedStepsAfterCompletedOne = steps.filter(
        (step, index) => !step.isCompleted && steps[index - 1]?.isCompleted
      );
      return notCompletedStepsAfterCompletedOne.includes(step);
    };

    return steps.map((step) => {
      const previousStepIsCompleted = step.previousActiveStep
        ? steps.findIndex((step) => step === step.previousActiveStep) <= step.completionIndex
        : true;

      const onClickHandler = async () => {
        if (previousStepIsCompleted) {
          setPageToShow(step.completionIndex);
        }
      };

      const shouldClickHandlerBeAvailable =
        checkStepStatus(step) || checkAllNotCompletedStepsAfterCompletedOne(step);

      return {
        label: step.label,
        isActive: pageToShow === step.completionIndex,
        isCompleted: step.isCompleted,
        position: step.completionIndex,
        onClick: shouldClickHandlerBeAvailable ? onClickHandler : undefined
      };
    });
  };

  const setQuestionnairePages = (newPages) => {
    setPages(newPages);
    const previousActiveStep = newPages.reverse().find((page) => {
      return page.completed === true;
    });
    const stepsOptions = newPages.reverse().map((page) => {
      return {
        label: page.title,
        completionIndex: page.position,
        previousActiveStep: previousActiveStep?.position || 0,
        isCompleted: page.completed
      };
    });
    if (!getUrlQueryParameter('step')) {
      const firstNotCompletedPage = newPages.find((page) => !page.completed && page.position);

      if (isFirst) {
        if (newPages.some((page) => !page.completed)) {
          handleSteps(firstNotCompletedPage.position);
        } else if (newPages.every((page) => page.completed)) {
          handleSteps(1);
        } else if (newPages.every((page) => !page.completed)) {
          handleSteps(1);
        } else {
          handleSteps(previousActiveStep?.position + 1 || 1);
        }
      }
    }
    setSteps(stepperSteps(stepsOptions));
  };

  useEffect(() => {
    try {
      if (isFirst) {
        const loadInitialQuestionnaire = async () => {
          setShowSpinner(true);
          const response = await dispatch(KYCActions.getApplication());
          const {
            payload: {
              applicationState: { questionnaireId }
            }
          } = response;
          const questionnaire = await dispatch(
            QuestionnaireActions.getQuestionnaire(questionnaireId)
          );
          setQuestionnairePages(questionnaire.payload.pages);
          setShowSpinner(false);
        };
        setIsFirst(false);
        loadInitialQuestionnaire();
      }
      if (questionnaireState.questionnaire && !isFirst)
        setQuestionnairePages(questionnaireState.questionnaire.pages);
    } catch (err) {
      setShowSpinner(false);
      history.push(routerPath.errorPage);
    }
  }, [questionnaireState.questionnaire, pageToShow]);

  useEffect(() => {
    if (getUrlQueryParameter('step')) {
      const pageToShow = pages.filter((page) => page.title === getUrlQueryParameter('step'));
      pageToShow && handleSteps(pageToShow?.[0]?.position);
    }
  }, [getUrlQueryParameter('step'), pages]);

  useEffect(() => {
    const company = JSON.parse(sessionStorage.getItem('company'));
    if (company) dispatch(OnboardingFlowHandlerActions.handleOnboardingFlow(company));
  }, []);

  return (
    <>
      {showSpinner ? (
        <LoaderWithText text={t('loader_preparing_questionnaire_text')} />
      ) : (
        <>
          {steps &&
            pages.map(
              (page) =>
                page?.position === pageToShow && (
                  <RenderPage
                    key={page.id}
                    page={page}
                    currentPage={pageToShow}
                    steps={steps}
                    totalPages={pages.length}
                    handlePrevStep={handleStepBack}
                    handleNextStep={handleStepForward}
                    handleNotCompletedStep={handleNotCompletedStep}
                    handleSteps={handleSteps}
                    allPagesCompleted={allPagesCompleted}
                  />
                )
            )}
        </>
      )}
    </>
  );
};

export const storageHandler = {
  getSessionStorage: (value) => {
    return JSON.parse(sessionStorage.getItem(value));
  },
  setSessionStorage: (key, value) => {
    sessionStorage.setItem(key, JSON.stringify(value));
  },
  getLocalStorage: (value) => {
    return JSON.parse(localStorage.getItem(value));
  },
  setLocalStorage: (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  },
  switchStorage: (key, storageType) => {
    if (storageType === 'sessionStorage') {
      const savedValue = sessionStorage.getItem(key);
      sessionStorage.removeItem(key);
      localStorage.setItem(key, savedValue);
    } else {
      const savedValue = localStorage.getItem(key);
      localStorage.removeItem(key);
      sessionStorage.setItem(key, savedValue);
    }
  }
};

import React, { useEffect, useState } from 'react';
import {
  AppConfigActions,
  AuthActions,
  KYCActions,
  selectGetAuthorizedCompaniesAuthorizations
} from '../../../../../Redux';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CompanyContainer } from './CompanyContainer/CompanyContainer';
import {
  CompaniesContainerStyles,
  CompanyDisplayContainerStyles,
  CompanyDisplayContainerWrapper,
  CompanyRepresentativeContainer,
  ModalStyles
} from './CompanyDisplayContainer.style';
import { CompanyRepresentativeForm } from './CompanyRepresentativeForm/CompanyRepresentativeForm';

import { NOTIFICATION_TYPES, Notification } from '../../../../../Components';
import { DataExchangeModal } from '../../DataExchangeModal/CompanyModal';
import { languageSelector } from '../../../../../Redux/Selectors/questionnaireFlowHandle';
import {
  ButtonSection,
  LoaderWithText
} from '../../../../ApplicationKYC/QuestionnaireStepsRender/components';
import CreateEmptyCompanyButton from './CreateEmptyCompanyButton/CreateEmptyCompanyButton';
import { checkJsonProp } from '../../../../../Utilities';

export const CompanyDisplayContainer = ({ clickBackHandler, handleCompanySelect }) => {
  const { t } = useTranslation();
  const [selectedCompanyIdentificationId, setSelectedCompanyIdentificationId] = useState('');
  const [isCompanyEmpty, setIsCompanyEmpty] = useState(true);
  const [data, setData] = useState('');
  const [company, setCompany] = useState('');
  const [sortedCompanies, setSortedCompanies] = useState([]);
  const [buttonBack, setButtonBack] = useState(false);
  const [step, setStep] = useState('Select');
  const [isLoading, setIsLoading] = useState(false);
  const [companyExistsError, setCompanyExistsError] = useState('');
  const availableCompanies = useSelector(selectGetAuthorizedCompaniesAuthorizations);
  const traceId = useSelector((state) => state.OnboardingFlowReducer.traceId);
  const dispatch = useDispatch();
  const language = useSelector(languageSelector);

  const handleBackClick = () => {
    if (buttonBack) {
      setStep('Select');
      setButtonBack(false);
    } else {
      clickBackHandler();
    }
  };

  const onChangeHandler = (companyidentificationId, companyName) => {
    if (companyName) {
      setIsCompanyEmpty(false);
    } else {
      setIsCompanyEmpty(true);
    }
    setSelectedCompanyIdentificationId(companyidentificationId);
  };

  const handleCompanySelectHandler = () => {
    handleCompanySelect(company);
  };

  const handleCompanyRepresentativeHandler = () => {
    setStep('Register');
    setButtonBack(true);
  };

  const createCompany = async (data) => {
    try {
      setIsLoading(true);
      const referalKey = localStorage.getItem('referalKey');
      const response = await dispatch(
        KYCActions.createApplication(data, selectedCompanyIdentificationId, referalKey)
      );
      await dispatch(
        AppConfigActions.updateApplicationSettings({
          applicationId: response.payload,
          language: language || 'lt-LT'
        })
      );
      handleCompanySelect(company);
      setIsLoading(false);
    } catch (e) {
      const value = checkJsonProp(e, 'response.data.errorCode');
      if (value === 'error_codes_company_can_not_register') {
        setIsLoading(false);
        setStep('Register');
        setCompanyExistsError(value);
      } else {
        Notification({
          type: NOTIFICATION_TYPES.ERROR,
          message: t('generic_error_message'),
          description: traceId
        });
        clickBackHandler();
      }
    }
  };

  const handleCompanyRepresentativeRegistrationHandler = async (data) => {
    setData(data);
    setIsLoading(true);
    const dataExchangeCheck = await dispatch(
      AuthActions.dataExchangeCheck(data.companyCode, data.country)
    );
    const { agreeToSellDataToOtherCompany } = dataExchangeCheck.payload;
    if (agreeToSellDataToOtherCompany) {
      setStep('DataExchange');
      setIsLoading(false);
    } else {
      setIsLoading(true);
      createCompany(data);
    }
  };

  const singleValidOndatoNoRegisteredCompany = (availableCompanies) => {
    if (!Array.isArray(availableCompanies) || availableCompanies.length != 1) {
      return false;
    }
    const company = availableCompanies[0];

    return !company.companyName && !company.companyCode;
  };

  const isRegisteredCompany = (availableCompanies) => {
    if (!Array.isArray(availableCompanies) || availableCompanies.length != 1) {
      return false;
    }
    const company = availableCompanies[0];

    return company.companyName && company.companyCode;
  };

  // skip the select page if single valid Ondato. Go to registration. TALE
  useEffect(() => {
    if (singleValidOndatoNoRegisteredCompany(availableCompanies)) {
      setStep('Register');
      setSelectedCompanyIdentificationId(availableCompanies[0].identificationId);
    } else if (isRegisteredCompany(availableCompanies)) {
      handleCompanySelect(availableCompanies[0]);
    } else {
      setStep('Select');
    }
  }, []);

  useEffect(() => {
    setCompany(
      availableCompanies?.find(
        (company) => company.identificationId === selectedCompanyIdentificationId
      )
    );
    if (company?.companyName && company?.companyCode) {
      setIsCompanyEmpty(false);
    } else {
      setIsCompanyEmpty(true);
    }
  }, [selectedCompanyIdentificationId, isCompanyEmpty]);

  useEffect(() => {
    setSortedCompanies(
      availableCompanies?.sort((a, b) => {
        if (!a.companyName || !a.companyCode) {
          return -1;
        }
        if (!b.companyName || !b.companyCode) {
          return 1;
        }
        return 0;
      })
    );
  }, [
    setSortedCompanies,
    sortedCompanies,
    handleCompanySelect,
    isCompanyEmpty,
    availableCompanies
  ]);

  const onContinueHandler = () => {
    const newData = {
      ...data,
      FetchOtherCompanyDataConsent: true
    };
    createCompany(newData);
  };

  const onCancelHandler = () => {
    createCompany(data);
  };

  const handleModalSteps = (step) => {
    if (step === 'Select') {
      return (
        <CompaniesContainerStyles>
          {(process.env.REACT_APP_ENV === 'TEST' || process.env.REACT_APP_ENV === 'STAGE') && (
            <CreateEmptyCompanyButton />
          )}
          {sortedCompanies?.map((company) => (
            <CompanyContainer
              key={company.identificationId}
              company={company}
              onChangeHandler={onChangeHandler}
              isSelected={company.identificationId === selectedCompanyIdentificationId}
            />
          ))}
        </CompaniesContainerStyles>
      );
    }
    if (step === 'Register') {
      return (
        <CompanyRepresentativeContainer>
          <p>{t('correct_company_code_for_represented_company')}</p>
          <CompanyRepresentativeForm
            onSubmit={handleCompanyRepresentativeRegistrationHandler}
            backButtonHandler={handleBackClick}
            isLoading={isLoading}
            companyExistsError={companyExistsError}
          />
        </CompanyRepresentativeContainer>
      );
    }
    if (step === 'DataExchange') {
      return (
        <DataExchangeModal
          isOpen={true}
          onContinue={onContinueHandler}
          onCancel={onCancelHandler}
        />
      );
    }
  };

  const getLabel = (step) => {
    if (step === 'Select' && !isLoading) {
      return t('select_your_company');
    }
    if (step === 'Register' && !isLoading) {
      return t('register_new_company');
    }
    return '';
  };

  return (
    <ModalStyles
      label={getLabel(step)}
      onCancel={handleBackClick}
      maskClosable={false}
      isOpen={true}
      hideSecondaryAction={true}
      footer={
        step === 'Select' && (
          <ButtonSection
            alignEnd={true}
            firstButtonClick={handleBackClick}
            secondButtonClick={
              !selectedCompanyIdentificationId || !isCompanyEmpty
                ? handleCompanySelectHandler
                : handleCompanyRepresentativeHandler
            }
            secondButtonPrimary={true}
            firstButtonText={step === 'Select' ? t('repeatable_group.cancel_button') : t('back')}
            secondButtonText={!isCompanyEmpty ? t('login_button') : t('next_button')}
            secondButtonDisabled={
              (!selectedCompanyIdentificationId && step === 'Select') || isLoading
            }
          />
        )
      }
    >
      <CompanyDisplayContainerWrapper>
        {isLoading ? (
          <LoaderWithText small={true} text={t('loader_preparing_questionnaire_text')} />
        ) : (
          <CompanyDisplayContainerStyles>{handleModalSteps(step)}</CompanyDisplayContainerStyles>
        )}
      </CompanyDisplayContainerWrapper>
    </ModalStyles>
  );
};

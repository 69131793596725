import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { RenderGroup } from '../../screens/renderGroup/renderGroup';
import { useFormValidation } from '../../validation/useFormValidation';
import CreateSchema from '../../validation/schemaBuilder';
import { createValidationSchema } from '../../hooks/createValidationSchema';
import { useDispatch, useSelector } from 'react-redux';
import { QuestionnaireActions } from '@redux';
import { useTranslation } from 'react-i18next';
import { formKeyInEdit } from '../../../../../Redux/Selectors/questionnaireFlowHandle';
import { AsideDrawer, AsideMenuTopBar, Modal } from '@single-platform/components';
import { TrashcanLogo } from '../../../../../Assets/images/logos';
import {
  AsideDrawerHeaderStyles,
  AsideDrawerStyles,
  ContentContainer,
  RemoveButtonStyles,
  RepeatableButtonSection
} from './repeatableDrawer.style';
import { BackDrop } from '../linkPopup/linkPopup.style';
import { Button } from '@single-platform/button';
import FillLocalStorage from '../../Utils/answersToLocalStorage';
import { storageHandler } from '../../../../SSOLoginContainer/storageHandler';
import { logError } from '../../../../../Utilities';

const RepeatableDrawer = ({
  group,
  currentPage,
  isModalOpen,
  modalToggleHandler,
  flow,
  groupId,
  errors,
  onRemove,
  removeButtonText,
  shownList,
  isInModal
}) => {
  const [initialValues, setInitialValues] = useState({});
  const [validationSchema, setValidationSchema] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const [isAtTop, setIsAtTop] = useState(true);
  const { handleSubmit, validationHelpers, setTouchedOnAll } = useFormValidation({
    initialValues,
    validationSchema
  });
  const { t } = useTranslation();
  const questionnaireState = useSelector((state) => state.QuestionnaireReducer);
  const dispatch = useDispatch();
  const selectFormKeyInEdit = useSelector(formKeyInEdit);
  const questionnaireChoices = useSelector((state) => state.QuestionnaireReducer.choices);

  let tempQuestions = [];

  const onQuestionChange = useCallback((question, action) => {
    const schema = new CreateSchema();
    if (action === 'delete') {
      tempQuestions = tempQuestions.filter((tempQuestion) => tempQuestion !== question);
    } else {
      tempQuestions = [...tempQuestions, question];
    }
    const { validationSchema, initialValues } = createValidationSchema(
      tempQuestions,
      'page',
      schema,
      t,
      questionnaireChoices,
      questionnaireState
    );

    setInitialValues(initialValues);
    setValidationSchema(validationSchema);
    dispatch(QuestionnaireActions.setFormKeyInEdit(selectFormKeyInEdit + 1));
  }, []);

  const filterOutAnswers = (answers) => {
    const localAnswers = storageHandler.getLocalStorage('answers');
    const keysToRemove = new Set(answers.map((item) => item.questionKey));
    const filteredArray = localAnswers.filter((item) => !keysToRemove.has(item.inputName));
    storageHandler.setLocalStorage('answers', filteredArray);
  };

  const handleOnInputChange = useCallback((name, value) => {
    value !== undefined &&
      FillLocalStorage({
        inputName: name,
        inputValue: value
      });
    validationHelpers.setValue(name, value);
  }, []);

  const handleStepForward = async (event) => {
    event.preventDefault();
    await handleSubmit(
      // on valid case
      async (data) => {
        modalToggleHandler();
        delete data.undefined;
        const restructuredData = Object.keys(initialValues)
          .map((key) => {
            return data[key] !== undefined && { questionKey: key, answer: data[key] };
            //filter removes null values
          })
          .filter((questionData) => questionData);
        if (restructuredData) {
          try {
            if (flow === 'add') {
              await dispatch(
                QuestionnaireActions.addQuestionnaireEntry(
                  questionnaireState.questionnaireId,
                  groupId,
                  restructuredData
                )
              );
            } else {
              dispatch(
                QuestionnaireActions.saveQuestionnaireAnswers(
                  questionnaireState.questionnaireId,
                  restructuredData
                )
              );
            }
          } catch (e) {
            logError(e);
          } finally {
            filterOutAnswers(restructuredData);
          }
        }
        dispatch(QuestionnaireActions.setFormKeyInEdit(selectFormKeyInEdit + 1));
      },
      // on invalid case
      () => {
        setTouchedOnAll();
      }
    )();
  };

  const handleModal = () => {
    setIsOpen(!isOpen);
    modalToggleHandler();
  };

  useEffect(() => {
    setIsOpen(isModalOpen);
  }, [isModalOpen]);

  const scrollPositionRef = useRef({
    scrollTop: 0,
    scrollHeight: 0,
    clientHeight: 0
  });

  const handleScroll = () => {
    const scrollableContent = document.getElementById('scrollable-content');
    if (scrollableContent) {
      const { scrollTop, scrollHeight, clientHeight } = scrollableContent;
      setIsAtBottom(scrollHeight - scrollTop === clientHeight);
      setIsAtTop(scrollTop === 0);
      scrollPositionRef.current = {
        scrollTop,
        scrollHeight,
        clientHeight
      };
    }
  };

  useEffect(() => {
    if (isOpen) {
      const drawer = document.getElementsByTagName('aside');
      if (drawer[0] && drawer[0].firstChild) {
        const scrollableContent = drawer[0].firstChild;
        scrollableContent.id = 'scrollable-content';
        scrollableContent.addEventListener('scroll', handleScroll);
        const { scrollTop, scrollHeight, clientHeight } = scrollableContent;
        setIsAtBottom(scrollHeight - scrollTop === clientHeight);
        setIsAtTop(scrollTop === 0);

        return () => {
          scrollableContent.removeEventListener('scroll', handleScroll);
        };
      }
    }
  }, [isOpen]);

  return (
    <>
      {isOpen && <BackDrop />}
      <AsideDrawerStyles>
        <AsideDrawer id="drawer" isOpen={isOpen}>
          <AsideDrawerHeaderStyles isAtTop={isAtTop}>
            <AsideMenuTopBar handleClose={handleModal} />
            <div>{shownList?.title || t('repeatable_group.add_button')}</div>
          </AsideDrawerHeaderStyles>
          <ContentContainer>
            <RenderGroup
              onQuestionChange={onQuestionChange}
              onInputChange={handleOnInputChange}
              key={group.id}
              initialQuestionValues={initialValues}
              group={group}
              errors={errors}
              currentPage={currentPage}
              isInModal={isInModal}
              repeatable
              validationHelpers={validationHelpers}
            />
            {removeButtonText && (
              <RemoveButtonStyles
                alignEnd={'true'}
                type="submit"
                onClick={(e) => onRemove(e, group.id)}
              >
                {removeButtonText} <TrashcanLogo />
              </RemoveButtonStyles>
            )}
            <RepeatableButtonSection isAtBottom={isAtBottom}>
              <Button onClick={(e) => handleStepForward(e)} key={Math.floor(Math.random())}>
                {flow !== 'edit'
                  ? t('repeatable_group.add_button')
                  : t('repeatable_group.edit_button')}
              </Button>
            </RepeatableButtonSection>
          </ContentContainer>
        </AsideDrawer>
      </AsideDrawerStyles>
    </>
  );
};

export default RepeatableDrawer;
